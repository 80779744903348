import React, {useEffect, useState} from 'react';
import './App.css';
import {DateFormat} from "./DateFormat";

const queryUrl = "https://65yif9dq41.execute-api.eu-west-2.amazonaws.com/v1/";

interface IDomain {
    "id": number,
    "name": string,
    "price": number,
    "owner": string,
    "expires": number,
    "premium": boolean,
    "created": number
}

function App() {

    const [, setTimer] = useState<NodeJS.Timeout>();
    const [lastTimestamp, setLastTimestamp] = useState((Date.now() - ((60*28)*1000)));
    const [domains, setDomains] = useState<IDomain[]>([]);

    useEffect(() => {
        const currTs = Date.now();
        setTimer(undefined);
        fetch(`${queryUrl}?start=${lastTimestamp}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then((res: {status: string, data: IDomain[]}) => {
            const domItems = domains;
            if (res.data.length > 0) {
                domItems.unshift(...res.data);
            }
            setDomains(domItems.slice(0, 50));
        }).finally(() => {
            setTimer(setTimeout(() => {
               setLastTimestamp(currTs);
           }, 5000));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastTimestamp]);

  return (
    <div className='app'>
      <div className='container'>
        <div className='logo'>
          <h1><strong>ENS</strong> Domains</h1>
          <h4>Real-time domain name registration dashboard</h4>
        </div>
        <div className='pending-bar'>
          We are waiting to new domain name registrations...
            <div className='bar-waiting'>
                <div className="bar-anim"></div>
            </div>
        </div>
        <div className='domains'>
            {domains.map((domain) => (
                <div className='domain' key={`id_${domain.id}`}>
                    <div>
                        <strong>{domain.name}<span>.eth</span></strong>
                        <span><DateFormat unix={Math.round(domain.created/1000)} /></span>
                        <span>Owner: {domain.owner}</span>
                    </div>
                    <div>
                        <strong>{domain.price.toFixed(4)} ETH</strong>
                        <span>Expires On</span>
                        <span>{new Date(domain.expires * 1000).toLocaleDateString()}</span>
                    </div>
                </div>
            ))}
        </div>
          <div className='how-to'>
              <h2>Tracking ENS Domain Events with Chainrel</h2>
              <div className='mb-2'>
                  With Chainrel, tracking Ethereum Name Service (ENS) domain events is easy. Follow these simple steps to get started:
              </div>
              <div>
                  <ol className='how-to-list'>
                      <li>
                          <strong>Create a Webhook</strong>
                          <p>Sign in to your Chainrel account.</p>
                          <p>Go to create a webhook page.</p>
                          <p>Select Ethereum as your preferred blockchain.</p>
                          <p>Select Contract Address as Source of Events.</p>
                          <p>Enter the Ethereum address of the ENS domain contract: "0x253553366da8546fc250f225fe3d25d0c782303b."</p>
                      </li>
                      <li>
                          <strong>Configure Your Webhook</strong>
                          <p>Choose the type of channel where you want to receive event notifications. You can use a URL endpoint or a Slack channel.</p>
                          <p>Enable the "NameRegistered" event.</p>
                          <p>You can access which data will be sent from the link on the right.</p>
                      </li>
                      <li>
                          <strong>Start Receiving Notifications</strong>
                          <p>Save the webhook settings.</p>
                          <p>Your webhook has been created and notifications for new ENS records will be sent to your specified notification target.</p>
                      </li>
                  </ol>
              </div>
              <div className='how-to-action'>
                  <h3>Get Started Now</h3>
                  <div className='mb-2'>Experience the power of Chainrel and events tracking like ENS registration events. <strong>Start for free today</strong>.</div>
                  <div>
                      <a href="https://chainrel.com?utm_source=ens-demo&utm_medium=content&utm_campaign=get_started" target="_blank" rel='noreferrer' className='btn-get-started'>Get Started</a>
                  </div>
              </div>
          </div>
      </div>
      <footer>
        <div className='built-with'>
          <span>Built with</span>
          <a href="https://chainrel.com?utm_source=ens-demo&utm_medium=footer&utm_campaign=footer_bar" target="_blank" rel="noreferrer"><img src="/extchainrel.svg" alt="Chainrel logo" /></a>
        </div>
      </footer>
    </div>
  );
}

export default App;
