import {FC} from "react";

const DateFormat : FC<{unix: number}> = ({unix}) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeDifference = currentTime - unix;

    let result;
    if (timeDifference < 60) {
        result = 'Just now';
    } else if (timeDifference < 3600) {
        const minutes = Math.floor(timeDifference / 60);
        result = `${minutes} minutes ago`;
    } else if (timeDifference < 86400) {
        const hours = Math.floor(timeDifference / 3600);
        result = `${hours} hours ago`;
    } else {
        const date = new Date(unix * 1000);
        result = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    }

    return (
        <>{result}</>
    )
};

export {DateFormat}
